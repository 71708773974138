<template>
  <div class="container">
    <van-nav-bar left-arrow @click-left="goBack" :title="title" />
    <h3>尊敬的家长朋友：</h3>
    <div
      class="survey-antdMobile-header-description"
      v-html="surveyDetail.surveyDesc"
    ></div>
    <van-form @submit="onSubmit">
      <div v-for="(res, idx) in questionList" :key="res.id">
        <div v-if="idx < 11">
          <template v-if="res.type === 'radio'">
            <!-- 问卷标题 -->
            <p
              :class="res.required == 0 ? 'smallTitle redStar' : ' smallTitle '"
            >
              {{ res.num }} .
              {{ res.title }}
            </p>
            <!-- 循环生成老师数量 -->
            <div v-for="(item, index) in teacherList" :key="index">
              <p>{{ item }}</p>
              <!-- 循环生成单选题选项 -->
              <van-radio-group
                v-model="answerList[idx][index].value"
                :class="
                  answerList[idx][index].required
                    ? 'radioBox '
                    : 'radioBox redddd'
                "
                @change="checkedQuestion(idx)"
              >
                <div
                  v-for="(child, num) in res.questionOptions"
                  :key="child.optionId"
                  class="singleRadio"
                >
                  <van-radio
                    :name="child.value"
                    @change="showClick(idx, num)"
                    >{{ child.optionContent }}</van-radio
                  >
                </div>
              </van-radio-group>
            </div>
          </template>
          <template v-if="res.type === 'textarea'">
            <p
              :class="res.required == 0 ? 'smallTitle redStar' : ' smallTitle '"
            >
              <!-- {{ res.num }} . -->
              {{ res.title }}
            </p>
            <div v-for="(item, index) in teacherList" :key="item.value">
              <p>{{ item }}</p>
              <textarea
                :class="answerList[idx][index].required ? ' ' : 'redddd'"
                name=""
                id=""
                cols="30"
                rows="2"
                v-model="answerList[idx][index].value"
                @change="checkedTextarea(idx)"
              ></textarea>
            </div>
          </template>
        </div>
        <div v-else>
          <template v-if="res.type === 'radio'">
            <!-- 问卷标题 -->
            <p
              :class="res.required == 0 ? 'smallTitle redStar' : ' smallTitle '"
            >
              {{ res.num }} .
              {{ res.title }}
            </p>
            <div>
              <van-radio-group
                v-model="answerList[idx][0].value"
                :class="
                  answerList[idx][0].required ? 'radioBox ' : 'radioBox redddd'
                "
                @change="checkedQuestion(idx)"
              >
                <div
                  v-for="(child, num) in res.questionOptions"
                  :key="child.optionId"
                  class="singleRadio"
                >
                  <van-radio
                    :name="child.value"
                    @change="showClick(idx, num)"
                    >{{ child.optionContent }}</van-radio
                  >
                </div>
              </van-radio-group>
            </div>
          </template>
          <template v-if="res.type === 'textarea'">
            <p
              :class="res.required == 0 ? 'smallTitle redStar' : ' smallTitle '"
            >
              {{ res.num }} .
              {{ res.title }}
            </p>
            <div>
              <textarea
                :class="answerList[idx][0].required ? ' ' : 'redddd'"
                name=""
                id=""
                cols="30"
                rows="2"
                v-model="answerList[idx][0].value"
                @change="checkedTextarea(idx)"
              ></textarea>
            </div>
          </template>
        </div>
      </div>

      <div>
        <van-button
          round
          block
          type="primary"
          native-type="submit"
          style="margin: 50px 0px"
        >
          提交
        </van-button>
      </div>
    </van-form>
    <div class="stone"></div>
    <van-popup round :style="{ padding: '64px' }">{{ msg }}</van-popup>
  </div>
</template>
<script>
import { submitSurvey, getSurveyDetails } from "@/api/questionsUpdate";
import { Toast } from "vant";
export default {
  data() {
    return {
      title: "测试表格",
      checked: "",
      value: "",
      addForm: [],
      teacherList: [],
      answerList: [],
      questionList: [],
      surveyDetail: undefined,
      show: false,
      msg: "1111",
    };
  },
  created() {
    this.getDetailSurvey();
  },
  methods: {
    checkedQuestion(row) {
      for (var i = 0; i <= row; i++) {
        this.answerList[i].forEach((child) => {
          if (child.value != "") {
            //   child.required = false;
            // } else {
            child.required = true;
          }
        });
      }
      if (row == 5 || row == 7 || row == 9) {
        const hasValue5 = this.answerList[row].some((item) => item.value == 5);
        this.questionList[row + 1].required = hasValue5 ? 0 : 1;
        this.answerList[row].forEach((child, num) => {
          if (child.value == 5) {
            this.answerList[row + 1][num].required = false;
          } else {
            this.answerList[row + 1][num].required = true;
          }
        });
      }
    },
    checkedTextarea(row) {
      if (row == 6 || row == 8 || row == 10) {
        this.answerList[row - 1].forEach((child, idx) => {
          if (child.value == 5) {
            if (this.answerList[row][idx].value != "") {
              this.answerList[row][idx].required = true;
            } else {
              this.answerList[row][idx].required = false;
            }
          }
        });
      }
      // this.answerList[row].forEach((child) => {
      //   if (child.required == false) {
      //     if (child.value == "") {
      //       child.required = false;
      //     } else {
      //       child.required = true;
      //     }
      //   }
      // });
    },
    showClick(idx, num) {
      console.log("第几题", idx, "第几项", num);
    },
    getDetailSurvey() {
      if (this.$route.params.item == undefined) {
        this.goBack();
      } else {
        let data = this.$route.params.item;
        this.surveyDetail = data.surveyRecord;
        this.teacherList = data.teacherNames;
        this.questionList = data.questionVos;
        this.reloadList(this.teacherList);
        if (data.surveyRecord.finished == 1) {
          this.rewrite(data.surveyRecord.surveyRecordId);
        }
      }
    },
    goBack() {
      this.$router.push("/list");
    },
    onSubmit() {
      let list = [];
      this.teacherList.forEach(() => {
        list.push([]);
      });
      let keep = true;
      this.questionList.forEach((result, idx) => {
        if (idx != 6 && idx != 8 && idx != 10) {
          if (result.required === 0 && keep) {
            this.answerList[idx].forEach((child) => {
              if (child.value == "") {
                child.required = false;
                this.$toast(`第${idx + 1}题有选项未完成！`);
                this.$nextTick(() => {
                  let isError = document.getElementsByClassName("redddd");
                  child.required = false;
                  isError[0].scrollIntoView({
                    // 滚动到指定节点
                    // 值有start,center,end，nearest，当前显示在视图区域中间
                    block: "center",
                    // 值有auto、instant,smooth，缓动动画（当前是慢速的）
                    behavior: "smooth",
                  });
                });
                keep = false;
              }
            });
          } else {
            return false;
          }
        } else {
          if (result.required === 0 && keep) {
            this.answerList[idx].forEach((child) => {
              if (child.required == false) {
                let j = 6
                if (idx == 6 ) {
                  j = 6
                }
                if (idx == 8 ) {
                  j = 7
                }
                if (idx == 10 ) {
                  j = 8
                }
                child.required = false;
                this.$toast(`第${j}题未完成！`);
                this.$nextTick(() => {
                  let isError = document.getElementsByClassName("redddd");
                  child.required = false;
                  isError[0].scrollIntoView({
                    // 滚动到指定节点
                    // 值有start,center,end，nearest，当前显示在视图区域中间
                    block: "center",
                    // 值有auto、instant,smooth，缓动动画（当前是慢速的）
                    behavior: "smooth",
                  });
                });
                keep = false;
              }
            });
          } else {
            return false;
          }
        }
      });
      if (keep) {
        this.answerList.forEach((answer) => {
          answer.forEach((res, index) => {
            list[index].push(res.value);
          });
        });
        list.push(this.teacherList);
        let subList = {
          surveyRecord: list,
          surveyId: this.surveyDetail.surveyRecordId,
        };
        submitSurvey(subList).then((res) => {
          if (res.data.code == 200) {
            this.msg = res.data.data;
            this.show = true;
            Toast.success(res.data.data);
            setTimeout(() => {
              this.goBack();
            }, 1000);
          } else if (res.data.code == 500) {
            Toast.fail(res.data.msg);
            setTimeout(() => {
              this.goBack();
            }, 1000);
          }
        });
      }
    },
    rewrite(id) {
      getSurveyDetails(id).then((res) => {
        let data = res.data.data.answers;
        this.answerList.forEach((child, index) => {
          child.forEach((arr, idx) => {
            arr.value = data[idx][index];
          });
        });
      });
    },
    reloadList(list) {
      let j = 0
      for (let i = 0; i < this.questionList.length; i++) {
        j++
        let arr = [];
        if (i > 10) {
          arr.push({ value: "", id: i, required: true });
        } else {
          if (i == 6 ) {
            j = 6
          }
          if (i == 8 ) {
            j = 7
          }
          if (i == 10 ) {
            j = 8
          }
          list.forEach((item) => {
            arr.push({ value: "", id: i + item.value, required: true});
          });
        }
        this.questionList[i].num = j
        this.answerList.push(arr);
      }
    },
    validator(val) {
      console.log(val);
    },
  },
};
</script>
<style scoped>
.container {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px 20px;
  box-sizing: border-box;
}
.container .smallTitle {
  margin: 30px 0;
}
.container .redStar::before {
  content: "* ";
  color: red;
}
.redddd {
  border: 0.5px solid red !important;
}
.radioBox {
  border: 0.5px solid #ccc;
}
.singleRadio {
  padding: 10px 10px;
  border: 0.5px solid #ccc;
}
.stone {
  width: 100%;
  height: 50px;
}
</style>
@/api/questionsUpdate
